import React, { useContext } from "react"
import PropTypes from "prop-types"
import WhiteLogo from "../../static/logo-white.png"
import NormalLogo from "../../static/logo.png"
// import styled from "styled-components"

// import { siteShortTitle } from "../../config"
import Context from "../context"

// const StyledLogo = styled.div`
//   position: relative;
//   z-index: 13;

//   font-size: ${({ size }) => (size ? size : "1.75rem")};
//   font-weight: 900;
//   color: ${({ theme, color }) => theme.colors[color] || color};

//   /* Disable effects when sidebar is open */
//   filter: none !important;
//   pointer-events: auto !important;
//   user-select: auto !important;
// `

// const Logo = ({ size, color }) => (
//   <StyledLogo color={color} size={size}>
//     {siteShortTitle}
//   </StyledLogo>
// )

const Logo = ({ type, screen }) => {
  const { darkMode } = useContext(Context).state
  if (darkMode && type === "header") {
    return (
      <img src={WhiteLogo} alt="logo" height={60} style={{ marginLeft: -16 }} />
    )
  } else if (type === "header") {
    return (
      <img
        src={NormalLogo}
        alt="logo"
        height={60}
        style={{ marginLeft: -16 }}
      />
    )
  } else {
    return (
      <div
        style={{
          display: "flex",
          alignItems: screen === "mobile" ? "center" : "start",
          flexDirection: "column",
          marginTop: screen === "mobile" ? "1.5rem" : 0,
          marginBottom: screen === "mobile" ? "0.5rem" : 0,
          marginLeft: screen === "mobile" ? 0 : -16,
        }}
      >
        <img src={WhiteLogo} alt="logo" height={40} />
        <span style={{ color: "#cfcfcf", fontSize: 12, marginTop: 5 }}>
          © 2023, WhatsAI. All Rights Reserved.
        </span>
      </div>
    )
  }
}

Logo.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
}

export default Logo
