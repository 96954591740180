import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import ContentWrapper from "../styles/contentWrapper"
import Context from "../context"
import Logo from "./logo"
// import { lightTheme, darkTheme } from "../styles/theme"
import { footerLinks } from "../../config"
import { detectMobileAndTablet, isSSR } from "../utils"

const StyledFooter = styled.footer`
  width: 100%;
  height: ${({ theme }) => theme.footerHeight};
  background: ${({ theme, darkMode }) =>
    darkMode ? theme.colors.background : theme.colors.primary};
  border-top: ${({ theme, darkMode }) =>
    darkMode ? `3px solid ${theme.colors.boxShadowHover}` : null};
  margin-top: 6rem;
`

const StyledMobileFooter = styled.footer`
  width: 100%;
  background: ${({ theme, darkMode }) =>
    darkMode ? theme.colors.background : theme.colors.primary};
  border-top: ${({ theme, darkMode }) =>
    darkMode ? `3px solid ${theme.colors.boxShadowHover}` : null};
  margin-top: 5.4rem;
`
const StyledContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-links {
      /* Adjust width of links wrapper accordingly */
      width: 10rem;
      display: flex;
      margin-right: -70px;
      justify-content: space-between;
      @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-right: 0px;
      }
      @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        width: 15rem;
      }
    }
  }
`

const StyledMobileContentWrapper = styled(ContentWrapper)`
  && {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1rem;
    .footer-links {
      /* Adjust width of links wrapper accordingly */
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
`

const StyledMobileLink = styled(Link)`
  margin-top: 0.9rem;
  font-weight: 700;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: ${({ theme, $darkMode }) =>
    $darkMode ? theme.colors.primary : theme.colors.background};
  letter-spacing: 1px;
`

const StyledLink = styled(Link)`
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme, $darkMode }) =>
    $darkMode ? theme.colors.primary : theme.colors.background};
  letter-spacing: 1px;
`

const Footer = () => {
  const { darkMode } = useContext(Context).state
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    let handleWindowSizeChange
    // if (isSSR) is necessary to prevent error during the gatsby build
    if (!isSSR) {
      handleWindowSizeChange = () => setWindowWidth(window.innerWidth)
      // set initial innerWidth when component mounts
      setWindowWidth(window.innerWidth)
    }
    // Add event listener to update windowWidth in state
    window.addEventListener("resize", handleWindowSizeChange)
    return () => window.removeEventListener("resize", handleWindowSizeChange)
  }, [windowWidth])

  if (detectMobileAndTablet(windowWidth + 700)) {
    return (
      <StyledMobileFooter darkMode={darkMode}>
        <StyledMobileContentWrapper>
          <div className="footer-links" data-testid="footer-links">
            {footerLinks.map(({ name, url }, key) => (
              <StyledMobileLink key={key} to={url} $darkMode={darkMode}>
                {name}
              </StyledMobileLink>
            ))}
          </div>
          <Link to="/" aria-label="home">
            <Logo
              screen="mobile"
              // size="1.5rem"
              // color={
              //   darkMode
              //     ? darkTheme.colors.primary
              //     : lightTheme.colors.background
              // }
            />
          </Link>
        </StyledMobileContentWrapper>
      </StyledMobileFooter>
    )
  } else {
    return (
      <StyledFooter darkMode={darkMode}>
        <StyledContentWrapper>
          <Link to="/" aria-label="home">
            <Logo
              screen="desktop"
              // size="1.5rem"
              // color={
              //   darkMode
              //     ? darkTheme.colors.primary
              //     : lightTheme.colors.background
              // }
            />
          </Link>
          <div className="footer-links" data-testid="footer-links">
            {footerLinks.map(({ name, url }, key) => (
              <StyledLink key={key} to={url} $darkMode={darkMode}>
                {name}
              </StyledLink>
            ))}
          </div>
        </StyledContentWrapper>
      </StyledFooter>
    )
  }
}

export default Footer
